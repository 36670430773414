<template>
    <form-section
        :sectionTitle="$t('applications.confirmation')"
        wide-first-col
    >
        <template v-slot:col-1>
            <form-element-group>
                <template v-slot:col-1>
                    <h3 class="mb-3">
                        {{ $t("applications.application-submitted") }}
                    </h3>
                    <v-row>
                        <v-col>
                            <p>{{ $t("applications.report-in-process") }}</p>

                            <v-btn color="primary" v-bind="homeUrl">{{
                                $t("applications.return-to-home")
                            }}</v-btn>
                            <!-- <v-hover v-slot="{ hover }">
                                <v-card
                                    :elevation="hover ? 16 : 2"
                                    :href="
                                        $utils.buildUrl(
                                            'portal',
                                            'orders/history'
                                        )
                                    "
                                >
                                    <v-card-text class="d-flex align-center">
                                        <v-avatar size="80" color="primary">
                                            <v-icon
                                                size="60"
                                                class="white--text"
                                                >mdi-clipboard-text-multiple</v-icon
                                            >
                                        </v-avatar>
                                        <h3 class="ml-3">View Order History</h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover> -->
                        </v-col>
                    </v-row>
                </template>
            </form-element-group>
        </template>
        <template v-slot:col-2> </template>
    </form-section>
</template>
<script>
import { mapGetters } from "vuex";
import HomeLink from "@/mixins/homelink";
export default {
    name: "applicant-confirmation",
    mixins: [HomeLink],
    components: {},
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        application: {
            type: Object,
            required: true,
        },
        step: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters("Applications", ["dataLoading"]),
    },
    watch: {},
    mounted() {},
    created() {},
    updated() {},
    methods: {},
};
</script>

<style scoped>
</style>