var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-section", {
    attrs: {
      sectionTitle: _vm.$t("applications.confirmation"),
      "wide-first-col": ""
    },
    scopedSlots: _vm._u([
      {
        key: "col-1",
        fn: function() {
          return [
            _c("form-element-group", {
              scopedSlots: _vm._u([
                {
                  key: "col-1",
                  fn: function() {
                    return [
                      _c("h3", { staticClass: "mb-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("applications.application-submitted")
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("applications.report-in-process")
                                  )
                                )
                              ]),
                              _c(
                                "v-btn",
                                _vm._b(
                                  { attrs: { color: "primary" } },
                                  "v-btn",
                                  _vm.homeUrl,
                                  false
                                ),
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("applications.return-to-home")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      },
      {
        key: "col-2",
        fn: function() {
          return undefined
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }